import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Intro</h2>
          <span className="image main">
            <img src='https://persona-project.s3-us-west-1.amazonaws.com/portfolio+pics/20180927_173822.jpg' alt="" />
          </span>
          <p>
            Hello, my name is James. A couple of years ago I was working at OpenTech Alliance as technical support, onboarding and helping
            clients with their new product. Although I loved interacting with customers and field technicians I was always drawn to the logic side of my job such as
             configuring layouts, timed triggers, unit alarms and Wiegand devices. I also I got to work with the development team
             to test new features with clients, which really sparked my interest. When the pandemic hit and I found myself working from home, I used the extra time I saved on the commute to study coding.
             Then I took the leap, gave notice, and started pursuing my dream.
             <br />
             Check out my <a href='https://docs.google.com/document/d/1Qsk3jFuk_0husXRI4u4m2kJEar50vga2EDQ1vV-lGhU/edit?usp=sharing'>Resum&eacute;</a>  
           
          </p>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Projects</h2>
          <h3>Covid Dashboard 
            <a href="https://github.com/covid-dashboard-group/covid-dashboard">
            <h4>(GitHub)</h4>
            </a>
            </h3>
          <span className="image main">
            <a href="http://104.248.66.60:4000/" >
            <img src='https://persona-project.s3-us-west-1.amazonaws.com/covid+dash.PNG' alt="" />
            </a>
          </span>
          <p>
            Worked as part of a team to create a Covid Dashboard that displays information unlike other sources. JavaScript | CSS | SASS | Bootstrap | HTML | Nivo | Mapbox
          </p>
          <a href="https://transport-tracker.jflorea.com/" >
          <span className="image main2">
            <img src='https://persona-project.s3-us-west-1.amazonaws.com/transport-tracker/TT-pic1.PNG' alt="" />
            <img src='https://persona-project.s3-us-west-1.amazonaws.com/transport-tracker/TT+pic+3.PNG' alt="" />
            <img src='https://persona-project.s3-us-west-1.amazonaws.com/transport-tracker/TT+pic+4.PNG' alt="" />
          </span>
          </a>
          <h3>Transport-Tracker(work in progress)
          <a href='https://github.com/tjf91/transport-tracker'>
            <h4>(GitHub)</h4>
          </a>

          </h3>
          <p>
            Developed an app to for trucking companies to visualize their drivers spending. JavaScript | CSS | SASS | Material UI | HTML | Nivo | Mapbox | React | Redux | Node | Express | Sessions | PosgreSQL
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src='https://persona-project.s3-us-west-1.amazonaws.com/portfolio+pics/27779_1453361378657_2247461_n.jpg' alt="" />            
          </span>
          <p>
            I am originally from Romania and my full name is Tudor James Florea. I picked up the James when I got my citizenship. I spent a good portion of my life
            being an athlete. I swim, bike, ski, hike and run. I also like to watch movies and play video games like normal people.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="https://getform.io/f/1cf89036-e4b7-4b8a-b98d-1520847f456d">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          <ul className="icons">         
            
            <li>
              <a href="https://www.linkedin.com/in/james-florea-97223617a/" className="icon fa-linkedin">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/tjf91"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
