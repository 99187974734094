import PropTypes from 'prop-types'
import React from 'react'

const Header = props => {
  const handleHover=()=>{
    let x= document.getElementById('logo-selfie')
    let y=document.getElementById('icon fa-diamond')
   x.style.display='block'
   y.style.display='none'
  }
const handleExitHover=()=>{
  let x= document.getElementById('logo-selfie')
  x.style.display='none'
  let y=document.getElementById('icon fa-diamond')
  y.style.display='block'
}
  return(
    <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo" onMouseEnter={handleHover} onMouseLeave={handleExitHover}>
      <span  className="icon fa-diamond" id='icon fa-diamond'></span>
      <img src='https://persona-project.s3-us-west-1.amazonaws.com/JF..png' alt='' id='logo-selfie' className='logo-selfie'/>
    </div>
    <div className="content">
      <div className="inner">
        <h1>James Florea</h1>
        <h4>Software Engineer</h4>
        <p>
          Developing creative solutions for businesses
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
            >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
            >
            Projects
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
            >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('contact')
            }}
            >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

}
Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
